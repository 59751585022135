import { CommonModule } from '@angular/common';
import { Component, computed } from '@angular/core';
import { IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { DarkModeService } from '../../services/dark-mode.service';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  standalone: true,
  imports: [CommonModule, IonHeader, IonToolbar, IonTitle],
})
export class MenuHeaderComponent {
  constructor(private readonly themeService: DarkModeService) {}

  public logoSrc = computed(() =>
    this.themeService.isDarkMode ? '/assets/logo/logo_okcargo_white.svg' : '/assets/logo/logo_okcargo.svg',
  );
}
