import { Routes } from '@angular/router';
import { ECapabilities } from './account/domain/account.model';
import { authGuard } from './auth/application/auth.guard';
import { loginGuard } from './auth/application/login.guard';
import { versionGuard } from './common/application/version.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layout/pages/tabs/tabs.routes').then((m) => m.routes),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.AUTHENTICATED },
  },
  {
    path: 'tabs/freight-finished/:id',
    loadComponent: () =>
      import('./layout/pages/freight-finished-detail/freight-finished-detail.page').then(
        (m) => m.FreightFinishedDetailPage,
      ),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.AUTHENTICATED },
  },
  {
    path: 'tabs/freight-inprogress/:id',
    loadComponent: () =>
      import('./layout/pages/freight-inprogress-detail/freight-inprogress-detail.page').then(
        (m) => m.FreightInprogressDetailPage,
      ),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.AUTHENTICATED },
  },
  {
    path: 'tabs/market/:id',
    loadComponent: () => import('./layout/pages/market-detail/market-detail.page').then((m) => m.MarketDetailPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.VIEW_MARKET },
  },
  {
    path: 'account',
    loadComponent: () => import('./layout/pages/account/account.page').then((m) => m.AccountPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.AUTHENTICATED },
  },
  {
    path: 'account/password',
    loadComponent: () => import('./layout/pages/password/password.page').then((m) => m.PasswordPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.AUTHENTICATED },
  },
  {
    path: 'my-company',
    loadComponent: () => import('./layout/pages/my-company/my-company.page').then((m) => m.MyCompanyPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.ADMINISTRATIVE_DOCUMENTS },
  },
  {
    path: 'posted-freight',
    loadComponent: () => import('./layout/pages/freight-posted/freight-posted.page').then((m) => m.FreightPostedPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.UPLOAD_FREIGHTS },
  },
  {
    path: 'posted-freight/post-freight',
    loadComponent: () => import('./layout/pages/freight-post/freight-post.page').then((m) => m.FreightPostPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.UPLOAD_FREIGHTS },
  },
  {
    path: 'posted-freight/:id',
    loadComponent: () =>
      import('./layout/pages/freight-posted-detail/freight-posted-detail.page').then((m) => m.FreightPostedDetailPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.UPLOAD_FREIGHTS },
  },
  {
    path: 'posted-freight/post-freight/:id',
    loadComponent: () => import('./layout/pages/freight-post/freight-post.page').then((m) => m.FreightPostPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.UPLOAD_FREIGHTS },
  },
  {
    path: 'vehicles',
    loadComponent: () => import('./layout/pages/vehicles/vehicles.page').then((m) => m.VehiclesPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.CRUD_VEHICLES },
  },
  {
    path: 'users',
    loadComponent: () => import('./layout/pages/users/users.page').then((m) => m.UsersPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.CRUD_USERS },
  },
  {
    path: 'help',
    loadComponent: () => import('./layout/pages/help/help.page').then((m) => m.HelpPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.AUTHENTICATED },
  },
  {
    path: 'support',
    loadComponent: () => import('./layout/pages/support/support.page').then((m) => m.SupportPage),
    canActivate: [versionGuard, authGuard],
    data: { requiredCapability: ECapabilities.AUTHENTICATED },
  },
  {
    path: 'login',
    loadComponent: () => import('./layout/pages/login/login.page').then((m) => m.LoginPage),
    canActivate: [versionGuard, loginGuard],
  },
  {
    path: 'recover-password',
    loadComponent: () =>
      import('./layout/pages/recover-password/recover-password.page').then((m) => m.RecoverPasswordPage),
    canActivate: [versionGuard, loginGuard],
  },
  {
    path: 'error',
    loadComponent: () => import('./layout/pages/error/error.page').then((m) => m.ErrorPage),
    canActivate: [versionGuard],
  },
  {
    path: 'update-required',
    loadComponent: () =>
      import('./layout/pages/update-required/update-required.page').then((m) => m.UpdateRequiredPage),
  },
  // catch all
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];
