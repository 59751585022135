import { Component, OnInit } from '@angular/core';
import { IonAlert, IonIcon, IonItem, IonLabel } from '@ionic/angular/standalone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
  standalone: true,
  imports: [IonAlert, IonLabel, IonIcon, IonItem, TranslateModule],
})
export class LangSelectComponent implements OnInit {
  private _defaultLang!: string;

  public alertButtons = [
    {
      text: '',
      role: 'confirm',
    },
  ];
  public alertInputs = [
    {
      label: '',
      type: 'radio',
      value: 'es',
    },
  ];

  constructor(
    private readonly translate: TranslateService,
    private readonly accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.accountService.account$.subscribe((account) => {
      if (account) {
        this._defaultLang = account.lang;
        this.assignButtonsAndInputsToAlert();
      }
    });
  }

  setLang(event: any): void {
    if (event.detail.role === 'confirm') {
      const newLang = event.detail.data.values;
      this._defaultLang = newLang;
      this.accountService.saveLang(newLang).then(() => this.assignButtonsAndInputsToAlert());
    }
  }

  private assignButtonsAndInputsToAlert(): void {
    this.alertInputs = [
      {
        label: this.translate.instant('general.lang.es'),
        type: 'radio',
        value: 'es',
      },
      {
        label: this.translate.instant('general.lang.en'),
        type: 'radio',
        value: 'en',
      },
    ];
    this.alertButtons = [
      {
        text: this.translate.instant('general.select'),
        role: 'confirm',
      },
    ];
    this.alertInputs = this.alertInputs.map((lang) => {
      if (lang.value === this._defaultLang) {
        return { ...lang, checked: true };
      } else {
        return { ...lang, checked: false };
      }
    });
  }
}
