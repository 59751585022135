import { CommonModule } from '@angular/common';
import { Component, EnvironmentInjector, HostListener, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { IonApp, IonContent, IonFooter, IonMenu, IonRouterOutlet, IonSplitPane } from '@ionic/angular/standalone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AccountService } from './account/application/account.service';
import { ECapabilities } from './account/domain/account.model';
import { JwtService } from './auth/infrastructure/jwt.service';
import { RefreshDataService } from './common/application/refresh-data.service';
import { AppInfoService } from './common/infrastructure/app-info.service';
import { StorageService } from './common/infrastructure/storage.service';
import { MenuFooterComponent } from './layout/components/menu-footer/menu-footer.component';
import { MenuHeaderComponent } from './layout/components/menu-header/menu-header.component';
import { MenuMainComponent } from './layout/components/menu-main/menu-main.component';
import { ScreensizeService } from './layout/services/screensize.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    CommonModule,
    IonApp,
    IonRouterOutlet,
    IonFooter,
    IonMenu,
    IonSplitPane,
    IonContent,
    TranslateModule,
    MenuHeaderComponent,
    MenuMainComponent,
    MenuFooterComponent,
  ],
  providers: [ScreensizeService],
})
export class AppComponent implements OnInit, OnDestroy {
  private _intervalRefreshFreights: any;
  private readonly _intervalSecons = 60000;

  public environmentInjector = inject(EnvironmentInjector);
  public isDesktopView$: Subscription = new Subscription();
  public menuPosition: string = 'end';
  public isAuthenticateUser = signal<boolean>(false);
  public isVersionSupported$: Observable<boolean | null>;

  public readonly userCapabilities$: Observable<ECapabilities[]> = this.accountService.userCapabilities$;
  public readonly ECapabilities = ECapabilities;

  constructor(
    private readonly storageService: StorageService,
    private readonly jwtService: JwtService,
    private readonly accountService: AccountService,
    private readonly appInfoService: AppInfoService,
    private readonly screensizeService: ScreensizeService,
    private readonly refreshDataService: RefreshDataService,
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {
    const browserLang = navigator.language || navigator.languages[0];
    const defaultLang = browserLang.startsWith('es') ? 'es' : 'en';
    this.translate.setDefaultLang(defaultLang);
    this.translate.use(defaultLang);

    this.accountService.account$.subscribe((account) => {
      if (account && this.translate.defaultLang !== account.lang) {
        this.translate.setDefaultLang(account.lang);
        this.translate.use(account.lang);
      }
    });

    this.isVersionSupported$ = this.appInfoService.isVersionSupported$();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: { target: { innerWidth: number } }): void {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  ngOnInit(): void {
    forkJoin({
      accessToken: this.storageService.read('accessToken'),
      refreshToken: this.storageService.read('refreshToken'),
      username: this.storageService.read('username'),
    }).subscribe({
      next: async (result) => {
        if (result.accessToken.value && result.refreshToken.value && result.username.value) {
          this.jwtService.setAccessToken(result.accessToken.value);
          this.jwtService.setRefreshToken(result.refreshToken.value);
          this.jwtService.setUsername(result.username.value);
          /*
          There is no need to ask for account data, accountService.checkUserAuthentication()
          is called in the authGuard  if there is a token in local storage, and it is
          always executed because at least the minimum AUTHENTICATED capability is checked.
          */
        }
      },
      error: (error) => {
        console.error('tokens:', error);
      },
    });

    this.jwtService.userDataReceived$.subscribe((isAuthenticated) => {
      if (this._intervalRefreshFreights) {
        clearInterval(this._intervalRefreshFreights);
      }

      if (isAuthenticated && !this.isAuthenticateUser()) {
        this.refreshDataService.refreshAll();
        // this.userCapabilities$ = this.accountService.userCapabilities$;
      } else if (!isAuthenticated && this.isAuthenticateUser()) {
        this.router.navigate(['/login'], { replaceUrl: true }).then(() => {
          window.location.replace('/login');
        });
      }

      this.isAuthenticateUser.set(isAuthenticated);

      this._intervalRefreshFreights = setInterval(() => {
        if (this.isAuthenticateUser()) {
          this.refreshDataService.refreshAll();
        }
      }, this._intervalSecons);
    });

    this.isDesktopView$ = this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      if (this.menuPosition === 'start' && !isDesktop) {
        this.menuPosition = 'end';
      } else if (this.menuPosition === 'end' && isDesktop) {
        this.menuPosition = 'start';
      }
    });
  }

  ngOnDestroy(): void {
    this.isDesktopView$.unsubscribe();
    if (this._intervalRefreshFreights) {
      clearInterval(this._intervalRefreshFreights);
    }
  }
}
