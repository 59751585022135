<ion-item
  button
  id="presentSelectLang"
>
  <ion-icon
    aria-hidden="true"
    src="../assets/material-symbols/language_FILL0_wght400_GRAD0_opsz24.svg"
    slot="start"
  ></ion-icon>
  <ion-label>{{ 'general.nav_bar.language' | translate }}</ion-label>
</ion-item>
<ion-alert
  trigger="presentSelectLang"
  header="{{ 'general.nav_bar.language' | translate }}"
  [buttons]="alertButtons"
  [inputs]="alertInputs"
  (didDismiss)="setLang($event)"
></ion-alert>
