import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  IonList,
  IonItem,
  IonMenuToggle,
  IonIcon,
  IonLabel,
} from '@ionic/angular/standalone';
import { ECapabilities } from 'src/app/account/domain/account.model';
import { AccountService } from 'src/app/account/application/account.service';

@Component({
  selector: 'app-menu-main',
  templateUrl: './menu-main.component.html',
  styleUrls: ['./menu-main.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    IonList,
    IonItem,
    IonMenuToggle,
    IonIcon,
    IonLabel,
  ],
})
export class MenuMainComponent {

  public readonly userCapabilities$: Observable<ECapabilities[]> =
    this.accountService.userCapabilities$;
  public readonly ECapabilities = ECapabilities;

  constructor(
    private accountService: AccountService,
  ) {}

}
