import { CommonModule } from '@angular/common';
import { Component, computed } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
} from '@ionic/angular/standalone';
import { DarkModeService } from '../../services/dark-mode.service';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    IonHeader,
    IonToolbar,
    IonTitle,
  ],
})
export class MenuHeaderComponent {


  constructor(
    private themeService: DarkModeService,
  ) {}

  public logoSrc = computed(() =>
    this.themeService.isDarkMode()
      ? '/assets/logo/logo_okcargo_white.svg'
      : '/assets/logo/logo_okcargo.svg'
  );

}
