<ion-app>

  <ion-split-pane when="md" contentId="main">

    @if ( isAuthenticateUser() && (isVersionSupported$ | async) ) {
    <ion-menu contentId="main" [side]="menuPosition">
      <app-menu-header></app-menu-header>

      <ion-content>
        <app-menu-main></app-menu-main>
      </ion-content>

      <ion-footer>
        <app-menu-footer></app-menu-footer>
      </ion-footer>

    </ion-menu>
    }

    <ion-router-outlet id="main"></ion-router-outlet>

  </ion-split-pane>
</ion-app>
