<ion-toolbar class="">
  <ion-list>

    <ion-menu-toggle autoHide="false">
      <app-lang-select></app-lang-select>
    </ion-menu-toggle>

    <ion-menu-toggle autoHide="false">
      <ion-item
        button
        routerLink="/help"
        routerLinkActive="active"
        routerDirection="root"
        detail="false"
      >
        <ion-icon
          aria-hidden="true"
          src="../assets/material-symbols/help_FILL0_wght400_GRAD0_opsz24.svg"
          slot="start"
        ></ion-icon>
        <ion-label>{{ 'general.nav_bar.help' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle autoHide="false">
      <ion-item
        button
        routerLink="/support"
        routerLinkActive="active"
        routerDirection="root"
        detail="false"
      >
        <ion-icon
          aria-hidden="true"
          src="../assets/material-symbols/support_agent_FILL0_wght400_GRAD0_opsz24.svg"
          slot="start"
        ></ion-icon>
        <ion-label>{{ 'general.nav_bar.support_contact' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ion-list>
</ion-toolbar>
