import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { JwtService } from '../infrastructure/jwt.service';
import { switchMap, map, take, from, of, catchError } from 'rxjs';
import { ECapabilities } from 'src/app/account/domain/account.model';
import { AccountService } from 'src/app/account/application/account.service';

export const loginGuard: CanActivateFn = (route, state) => {
  const accountService = inject(AccountService);
  const jwtService = inject(JwtService);
  const router = inject(Router);

  return jwtService.userDataReceived$.pipe(
    take(1),
    switchMap((userDataReceived) => {
      if (userDataReceived) {
        return accountService.hasCapability$(ECapabilities.AUTHENTICATED).pipe(
          map((hasCap) => {
            if (hasCap) {
              router.navigate(['/tabs/dashboard']);
              return false;
            }
            return true;
          })
        );
      } else {
        const accessToken = jwtService.accessToken;
        const refreshToken = jwtService.refreshToken;
        if (accessToken && refreshToken) {
          return from(accountService.checkUserAuthentication()).pipe(
            switchMap(() => accountService.hasCapability$(ECapabilities.AUTHENTICATED)),
            map((hasCap) => {
              if (hasCap) {
                router.navigate(['/tabs/dashboard']);
                return false;
              }
              return true;
            }),
            catchError(() => of(true))
          );
        } else {
          return of(true);
        }
      }
    })
  );
};

