import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LangSelectComponent } from 'src/app/account/application/lang-select/lang-select.component';
import { IonToolbar, IonList, IonMenuToggle, IonIcon, IonLabel } from '@ionic/angular/standalone';
import { ECapabilities } from 'src/app/account/domain/account.model';
import { AccountService } from 'src/app/account/application/account.service';

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    LangSelectComponent,
    IonToolbar,
    IonList,
    IonMenuToggle,
    IonIcon,
    IonLabel,
  ],
})
export class MenuFooterComponent {
  public readonly userCapabilities$: Observable<ECapabilities[]> = this.accountService.userCapabilities$;
  public readonly ECapabilities = ECapabilities;

  constructor(private readonly accountService: AccountService) {}
}
