<ion-header class="ion-no-border">
  <ion-toolbar mode="md">
    <ion-title>
      <img
        [src]="logoSrc()"
        alt="okcargo"
        class="menu-logo"
      />
    </ion-title>
  </ion-toolbar>
</ion-header>
